import { useTranslation } from "react-i18next";
import { useUser } from "hooks/dev";
import { Layout } from "components/common/Layout";
import { Container } from "components/common";

export const Subscription = () => {
  const { t } = useTranslation();
  const { userInfo } = useUser();
  const { userId, isActive } = userInfo || {};

  return (
    <Layout>
      <Container>
        <div className="pt-[10px] w-full h-full flex flex-col">
          <div className="text-3xl text-main mb-4 font-bold">
            {t("subscription", { defaultValue: "Subscription" })}
          </div>
          {isActive && (
            <div className="text-xl text-main mb-4 font-bold">
              {t("status", { defaultValue: "Status" })}:{" "}
              <span className="text-green-600">
                {t("Activated", { defaultValue: "Activated" })}
              </span>
            </div>
          )}
        </div>
      </Container>
    </Layout>
  );
};
